.contactUs {
  margin-top: 4em;
  margin-bottom: 10%;
  color: var(--clr-purple);
}

.bold {
  font-weight: var(--fw-bold);
}

.regular {
  font-weight: var(--fw-reg);
}
