.mainheading {
  border: 1px solid #f1f1f1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  gap: 10px;
  margin-bottom: 2em;
}
.headingtitle {
  font-size: 16px;
  font-weight: var(--fw-semibold);
}
.radiogroup {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
}

.radiotext {
  font-size: 14px;
}

.radiotext input {
  margin-right: 10px;
  accent-color: black;
}

.subs {
  background: var(--clr-grey);
  display: grid;
  grid-template-columns: 25% 15.5% 15.5% 20% 21%;
  grid-template-areas: 'main1 main2 main3 main4 main5';
  height: 46px;
  align-items: center;
  text-align: right;
  font-weight: var(--fw-reg);
  grid-column-gap: 0.2em;
  margin-bottom: 1em;
  cursor: pointer;
  padding-left: 1em;
}
.subs > div:nth-child(1) {
  text-align: left;
}
.subs:hover {
  background: #e5e5e5;
}
.substext {
  font-weight: var(--fw-reg);
}

.gridmain1 {
  grid-area: main1;
}
.gridmain2 {
  grid-area: main2;
}
.gridmain3 {
  grid-area: main3;
}
.gridmain4 {
  grid-area: main4;
}
.gridmain5 {
  grid-area: main5;
}

.headers {
  align-items: center;
  text-align: right;
  font-weight: 700;
  grid-column-gap: 0.2em;
  display: grid;
  grid-template-columns: 25% 15.5% 15.5% 20% 21%;
  margin-bottom: 1em;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-block: 24px;
}

.pagination button {
  padding: 5px 10px;
  font-size: 12px;
  font-weight: var(--fw-bold);
  border: 1px solid transparent;
  border-radius: 3px;
  background: none;
  color: var(--clr-orangebutton);
  transition: none;
}

.pagination button.active {
  background-color: var(--clr-orangebutton);
  color: #fff;
}

.pagination button:hover:not(:disabled) {
  border-color: var(--clr-orangebutton);
}

.pagination button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.pagination button:focus {
  outline: none;
}

.contactframe {
  border: 1px solid #f1f1f1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 14px;
  font-size: 12px;
  font-weight: var(--fw-reg);
  gap: 1em;
}

@media (max-width: 1006px) {
  .subs,
  .headers {
    grid-template-columns: 25% repeat(2, 15.5%) 20% 21%;
  }
}

@media (max-width: 596px) {
  .subs {
    grid-template-columns: 25% repeat(2, 17%) 20% 21%;
    height: 98px;
    padding-right: 1em;
  }
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
