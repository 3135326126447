:root {
  font-size: 13px;

  --fw-bold: 700;
  --fw-semibold: 600;
  --fw-reg: 400;
  --fw-light: 300;

  --clr-primary: #1f2125;
  --clr-line: #d9d9d9;
  --clr-purple: #606ebb;
  --clr-purplefaded: #9da5d2;
  --clr-green: #90d918;
  --clr-yellow: #ffcc00;
  --clr-grey: #f1f1f1;
  --clr-greyfaded: #f1f1f1e6;
  --clr-orangebutton: #e86d2b;
  --clr-blue: #337ab7;
  --clr-bluefaded: #337ab7e6;
}

html {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 13px;
  font-weight: normal;
  background-color: #fff;
  box-sizing: border-box;
  color: var(--clr-primary);
  height: 100%;
}

.app-container {
  display: flex;
  flex-direction: column;
  padding: 1em;
  margin: 1em;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  position: relative;
}

.span2 {
  grid-column: span 2;
}

.grid-columns-2 {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  align-items: center;
  grid-row-gap: 0.25em;
  font-size: 14px;
  line-height: normal;
}

.grid-columns-2 + .grid-columns-2 {
  margin-top: 1em;
}

.dwinputbox {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  outline: 0;
  line-height: normal;
  color: #69727d;
  border-radius: 0;
  padding: 16px;
  background: #f9fafa;
  border: none;
  font-size: 14px;
}

select.dwinputbox {
  padding: 0.424rem 1rem;
  border-radius: 3px;
}

select.dwselectbox {
  margin-top: 8px;
  padding-inline: 0.6rem;
  border: 1px solid #666;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid var(--clr-orangebutton);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pos-center {
  position: fixed;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.pos-center-dialog {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.testdb-spinner-container {
  justify-content: left;
  justify-self: flex-end;
}

.testdb-close-btn {
  align-self: flex-end;
  justify-self: flex-end;
  grid-column: -2 / -1;
}

.dialog-response-area {
  min-height: 120px;
  width: 100%;
  padding: 20px 0;
}

.error {
  color: #e60c0c !important;
  line-height: 1;
}

.success {
  color: #90d918 !important;
  line-height: 1;
}

.text-center {
  text-align: center;
}
