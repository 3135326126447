.noSubsContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 95px;
  position: relative;
}
.noSubsHeading {
  font-size: 16px;
  font-weight: var(--fw-semibold);
  color: #2a2d3d;
  margin-top: 2em;
  text-align: center;
}
.noSubsText {
  font-size: 13px;
  color: #1f2125;
  margin-top: 2em;
  text-align: center;
}
