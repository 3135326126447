.detailintro {
  background: var(--clr-grey);
  display: grid;
  grid-template-areas: 'header1 header2 header3 header4';
  height: 46px;
  align-items: center;
  grid-column-gap: 0.2em;
  margin-bottom: 1em;
  font-weight: var(--fw-reg);
  padding-right: 1em;
  padding-left: 1.5em;
}

.detailgrid {
  display: grid;
  grid-column-gap: 0.2em;
  margin-bottom: 1em;
  font-weight: var(--fw-reg);
  padding-right: 1.5em;
  padding-left: 0.5em;
  align-items: center;
  gap: 1em;
  grid-template-columns: 20% 25% 20% 33%;
  grid-template-areas: 'purchase order database password' 'sepline sepline sepline sepline' 'purchase2 order2 database2 password2' 'purchase3 order3 database3 password2' 'purchase4 order4 database4 password2';
}

.detailheading {
  font-size: 10px;
  font-weight: var(--fw-bold);
  padding-right: 1em;
  padding-left: 1.5em;
  align-self: flex-start;
  overflow-wrap: break-word;
}

.btnContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}

.btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 16px;
  font-size: 14px !important;
  line-height: normal;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  text-decoration: none !important;
}

.btn.btn-primary {
  font-weight: var(--fw-reg);
  color: #fff;
  background-color: var(--clr-blue);
  border-color: #2e6da4;
}

.btn.btn-primary:hover {
  background-color: var(--clr-bluefaded);
}

.btn.btn-secondary {
  color: var(--clr-primary);
  background-color: var(--clr-grey);
  border-color: var(--clr-grey);
}

.btn.btn-secondary:hover {
  color: var(--clr-primary);
  background-color: var(--clr-greyfaded);
}

.border-grey {
  border: 1px solid #dfdfdf !important;
}

div.detailheading:nth-child(4) {
  text-align: right;
}
.detailfield {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  font-weight: var(--fw-reg);
}

.row-border {
  border-top: 1px solid #f1f1f1;
}

.emailbtn {
  background-color: var(--clr-orangebutton);
  color: #fff;
  border-radius: 5px;
  width: 180px;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 10px;
  align-self: flex-end;
  font-size: 12px;
  cursor: pointer;
}

.disabled-grey {
  background: var(--clr-line);
  cursor: default;
}

.dialog {
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
  top: -300px;
  left: 50%;
  margin-left: -200px;
  width: 400px;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 1em;
  background: #fff;
  border: 1px solid #ccc;
  border-color: rgba(0, 0, 0, 0.2);
  color: #000;
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  position: relative;
  -webkit-animation: menu 0.2s;
  animation: menu 0.2s;
  z-index: 999;
  padding: 1em;
  justify-items: center;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.date-dialog::-webkit-scrollbar,
.dialog::-webkit-scrollbar {
  display: none;
}

.date-dialog {
  --modalWidth: min(400px, 100%);
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 2em;
  background: #fff;
  border: 1px solid #ccc;
  border-color: rgba(0, 0, 0, 0.2);
  color: #000;
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  width: var(--modalWidth);
  position: absolute;
  left: calc(50% - var(--modalWidth) / 2);
  top: 50%;
  transform: translateY(-50%);
  -webkit-animation: menu 0.2s;
  animation: menu 0.2s;
  z-index: 999;
  padding: 1em;
  justify-items: center;
  align-items: center;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.connection-dialog {
  overflow-y: hidden;
}

/* .dialog-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1em;
} */

.dialogtext {
  grid-column: span 2;
  font-size: 12px;
  font-style: italic;
}
.dwbutton {
  outline: none;
  background: none;
  border: none;
  border-radius: 5px !important;
}
.dialogitem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  gap: 10px;
  font-size: 12px;
  font-weight: var(--fw-bold);
  text-decoration: underline;
  color: var(--clr-purple);
  border-radius: 5px;
  width: 77px;
  height: 25px;
  cursor: pointer;
}

.dialogitem:hover {
  background-color: var(--clr-purple);
  color: #fff;
  text-decoration: none;
}

.dialogitem:hover:disabled {
  background-color: unset;
  color: var(--clr-purple);
  text-decoration: underline;
  cursor: not-allowed;
}

.gridheader1 {
  grid-area: header1;
}
.gridheader2 {
  grid-area: header2;
}
.gridheader3 {
  grid-area: header3;
}
.gridheader4 {
  grid-area: header4;
}

.gridpassword {
  grid-area: password;
}
.gridpassword2 {
  grid-area: password2;
}
.gridorder {
  grid-area: order;
}
.gridorder2 {
  grid-area: order2;
}
.gridorder3 {
  grid-area: order3;
}
.gridorder4 {
  grid-area: order4;
}
.griddatabase {
  grid-area: database;
}
.griddatabase2 {
  grid-area: database2;
}
.griddatabase3 {
  grid-area: database3;
}
.griddatabase4 {
  grid-area: database4;
}
.gridpurchase {
  grid-area: purchase;
}
.gridpurchase2 {
  grid-area: purchase2;
}
.gridpurchase3 {
  grid-area: purchase3;
}
.gridpurchase4 {
  grid-area: purchase4;
}
.gridline {
  grid-area: sepline;
}
.gridline2 {
  grid-area: sepline2;
}
.gridline3 {
  grid-area: sepline3;
}
.gridline4 {
  grid-area: sepline4;
}

.is-disabled {
  pointer-events: none;
  opacity: 0.7;
}

@media (max-width: 1200px) {
  .detailgrid {
    padding-top: 1em;
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: auto;
    grid-template-areas: 'purchase . . .' 'sepline sepline sepline sepline' 'purchase2 . . .' 'purchase3 . . .' 'purchase4 . . .' '. . . .' 'order . . .' 'sepline2 sepline2 sepline2 sepline2' 'order2 . . .' 'order3 . . .' 'order4 . . .' '. . . .' 'database . . .' 'sepline3 sepline3 sepline3 sepline3' 'database2 . . .' 'database3 . . .' 'database4 . . .' '. . . .' 'password . . .' 'sepline4 sepline4 sepline4 sepline4' 'password2 password2 password2 password2';
  }
  .detailheading {
    padding: 0;
  }
  #passDiv {
    justify-self: flex-start !important;
    width: 100%;
  }
  .emailbtn {
    width: auto;
  }
  .resetBtn {
    width: 100%;
  }
  #password {
    text-align: left;
  }
  .dialog {
    width: 200px;
    margin-top: 200px;
    margin-left: -100px;
  }
  .dialog.gbq {
    width: auto;
    max-width: 400px;
    margin-left: 0;
    transform: translateX(-50%);
  }
  .detailintro {
    grid-template-areas: 'header1 . header2' 'header3 . .' 'header4 header4 header4';
    height: 112px;
  }
  .gridheader3,
  .gridheader4 {
    align-self: baseline;
  }
  .gridheader2 {
    text-align: end;
  }
}

@media (max-width: 420px) {
  .contactframe {
    flex-direction: column;
  }
  .dialog.gbq {
    justify-items: unset;
  }
  .grid-columns-2 {
    grid-template-columns: 1fr;
  }
}

form.dialogform {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

:root {
  --toastify-z-index: 10001 !important;
  /* Default z-index for Toastify : 9999 , we are setting it to 10001 cause the header has z-index of 10000 */
}

.disabled-grey.popover {
  position: relative;
  cursor: not-allowed;
  text-align: center;
}
.disabled-grey.popover:hover::before {
  content: 'Upgrade to a higher tier of service to unlock this feature';
  visibility: visible;
  width: 220px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  transition: opacity 0.3s;
}

.disabled-grey.popover:hover::after {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.disabled-grey.popover::before,
.disabled-grey.popover::after {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}

.disabled-grey.popover:hover::before,
.disabled-grey.popover:hover::after {
  visibility: visible;
  opacity: 1;
}

.dialog.success-time {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dialog.success-time p {
  color: black !important;
}
